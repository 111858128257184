<template>
  <div>
    <div class="row justify-content-center">
      <!-- <div :class="{
        'footer-login-home': type === 'home',
        'footer-login': type !== 'home',
      }" v-if="!getIsMobile">
        <p>{{ $t("base.footer_base") }}</p>
        <a href="https://www.voompcreators.com.br/" target="_blank" rel="noopener noreferrer">
          <img v-if="getTheme == 'dark'" src="@/assets/logo-white.png" alt="logovoomp" />
          <img v-if="getTheme == 'light'" src="@/assets/logo.png" alt="logovoomp" />
        </a>
      </div>
      <div :class="{
        'footer-login-home': type === 'home',
        'footer-login-mobile': type !== 'home',
      }" v-if="getIsMobile">
        <p>{{ $t("base.footer_base") }}</p>
        <a href="https://www.voompcreators.com.br/" target="_blank" rel="noopener noreferrer">
          <img v-if="getTheme == 'dark'" src="@/assets/logo-white.png" alt="logovoomp" />
          <img v-if="getTheme == 'light'" src="@/assets/logo.png" alt="logovoomp" />
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["type"],
  data() {
    return {
      client: {
        width: 0,
      }
    };
  },
  computed: {
    ...mapGetters({
      getIsMobile: "layout/getIsMobile",
      getTheme: "config/getTheme",
    }),
  }
};
</script>

<style lang="scss" scoped>
.footer-login {
  position: absolute;
  bottom: 10px;
  display: flex;
}

.footer-login-home {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.footer-login p,
.footer-login-home p {
  color: var(--gray-600, #757575);
  text-align: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0.22rem;
  font-family: Inter;

}

.footer-login span,
.footer-login-home span {
  font-weight: 600;
  color: var(--maincolor);
}

.footer-login img,
.footer-login-home img {
  width: 100px;
  margin-top: -1px;
}

.footer-login-mobile {
  position: absolute;
  bottom: 10px;
  display: flex;
}

.footer-login-mobile img {
  width: 100px;
  margin-top: -1px;
}

.footer-login-mobile p {
  font-size: 10px;
  font-family: Inter;
  margin-right: 15px;
}

.footer-login-mobile span {
  font-weight: 600;
  color: var(--maincolor);
}
</style>
