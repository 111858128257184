<template>
  <span>
    <slot></slot>
    <vue-programmatic-invisible-google-recaptcha
      ref="invisibleRecaptcha1"
      :sitekey="getEnvCaptcha()"
      :elementId="'invisibleRecaptcha1'"
      :badgePosition="'right'"
      :showBadgeMobile="true"
      :showBadgeDesktop="true"
      @recaptcha-callback="recaptchaCallback"
    >
    </vue-programmatic-invisible-google-recaptcha>
  </span>
</template>

<script>
export default {
  data() {
    return {
      recaptchaToken: null,
    };
  },
  methods: {
    isCypress(){
      const userAgent = process.env.VUE_CYPRESS_USER_AGENT || undefined
      return navigator.userAgent.includes(userAgent)
    },
    getEnvCaptcha() { 
      if (this.$route.path === "/set-password" || this.$route.path === "set-password") {
        return process.env.VUE_KEY_CAPTCHA_BACK_ADM;
      }
      return process.env.VUE_KEY_CAPTCHA;
    },
    resetCaptcha() {
      this.$refs.invisibleRecaptcha1.reset();
    },
    setCaptcha() {
      if(this.isCypress()){
        console.warn('Captcha was ignored by Cypress!');
        this.$emit("changeVerify", "error");
        return
      }
      this.$refs.invisibleRecaptcha1.execute();
    },
    recaptchaCallback(recaptchaToken) {
      this.$emit("changeVerify", recaptchaToken);
    },
  },
};
</script>